<template>
  <v-container
    id="wizard"
    tag="section"
    fluid
    class="blanketaccent"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="10"
      >
        <v-card
          style="border-radius: 20px;"
          elevation="0"
        >
          <v-stepper
            v-model="e1"
            elevation="0"
          >
            <v-stepper-header>
              <v-stepper-step
                :complete="e1 > 1"
                step="1"
              >
                About you
              </v-stepper-step>

              <v-divider class="primary" />

              <v-stepper-step
                :complete="e1 > 2"
                step="2"
              >
                Lifestyle
              </v-stepper-step>

              <v-divider class="primary" />

              <v-stepper-step
                :complete="e1 > 3"
                step="3"
              >
                Health
              </v-stepper-step>
              <v-divider class="primary" />

              <v-stepper-step step="4">
                Review
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-card
                  min-height="300"
                  elevation="0"
                >
                  <div
                    class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"
                  >
                    Personal Information
                  </div>
                  <v-row class="justify-center">
                    <v-col
                      cols="12"
                      md="8"
                    >
                      <v-text-field
                        v-model="currentuser.firstname"
                        :rules="present"
                        label="First Name"
                        color="primary"
                        outlined
                        @change="updateInterview('firstname')"
                      />
                      <v-text-field
                        v-model="currentuser.lastname"
                        :rules="present"
                        label="Last Name"
                        color="primary"
                        outlined
                        @change="updateInterview('lastname')"
                      />
                      <v-text-field
                        v-model="currentuser.ssn"
                        :rules="numeric_only"
                        label="Social Security Number"
                        color="primary"
                        outlined
                        @change="updateInterview('ssn')"
                      />
                      <v-text-field
                        v-model="currentuser.email"
                        :rules="emailRules"
                        label="Email"
                        color="primary"
                        outlined
                        @change="updateInterview('email')"
                      />
                      <v-text-field
                        v-model="currentuser.mobile"
                        :rules="present"
                        label="Mobile Phone"
                        color="primary"
                        outlined
                        @change="updateInterview('mobile')"
                      />
                      <v-text-field
                        v-model="currentuser.address"
                        :rules="present"
                        label="Address"
                        color="primary"
                        outlined
                        @change="updateInterview('Address')"
                      />
                      <v-text-field
                        v-model="currentuser.city"
                        :rules="present"
                        label="City"
                        color="primary"
                        outlined
                        @change="updateInterview('City')"
                      />
                      <v-text-field
                        v-model="currentuser.postcode"
                        :rules="numeric_only"
                        label="Zipcode"
                        color="primary"
                        outlined
                        @change="updateInterview('postcode')"
                      />
                    </v-col>
                  </v-row>
                </v-card>
                <v-btn
                  color="primary"
                  :disabled="validpersonal"
                  @click="nextquestion('term life options')"
                >
                  Continue
                </v-btn>

                <v-btn
                  text
                  @click="cancel"
                >
                  Cancel
                </v-btn>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-card
                  min-height="300"
                  elevation="0"
                >
                  <div
                    class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"
                  >
                    Occupation, Finances and Lifestyle
                    <v-row justify="center">
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        lg="4"
                      >
                        <v-divider
                          class="test text-center"
                        />
                      </v-col>
                    </v-row>
                  </div>
                  <v-row class="justify-center">
                    <v-col
                      cols="12"
                      md="8"
                    >
                      <p>{{ getquestiontext('Marital Status') }}</p>
                      <v-select
                        v-model="HEALTH_INTERVIEW.labelkey['Marital Status']"
                        :rules="present"
                        :items="marital_options"
                        label="Select"
                        item-value="answerValue"
                        item-text="answerText"
                        color="primary"
                        outlined
                        @change="updateInterview('Marital Status')"
                      />
                      <p>{{ getquestiontext('occupation') }}</p>
                      <v-text-field
                        v-model="HEALTH_INTERVIEW.labelkey['occupation']"
                        color="primary"
                        :rules="present"
                        outlined
                        @change="updateInterview('occupation')"
                      />
                      <p>{{ getquestiontext('Employer') }}</p>
                      <v-text-field
                        v-model="HEALTH_INTERVIEW.labelkey['employer']"
                        color="primary"
                        :rules="present"
                        outlined
                        @change="updateInterview('employer')"
                      />
                      <p>{{ getquestiontext('business type') }}</p>
                      <v-text-field
                        v-model="HEALTH_INTERVIEW.labelkey['business type']"
                        color="primary"
                        :rules="present"
                        outlined
                        @change="updateInterview('business type')"
                      />
                      <p>{{ getquestiontext('Duties') }}</p>
                      <v-text-field
                        v-model="HEALTH_INTERVIEW.labelkey['Duties']"
                        color="primary"
                        :rules="present"
                        outlined
                        @change="updateInterview('Duties')"
                      />
                      <p>{{ getquestiontext('Business Address') }}</p>
                      <v-text-field
                        v-model="HEALTH_INTERVIEW.labelkey['Business Address']"
                        color="primary"
                        :rules="present"
                        outlined
                        @change="updateInterview('Business Address')"
                      />
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                          lg="6"
                        >
                          <p>{{ getquestiontext('Business City') }}</p>
                          <v-text-field
                            v-model="HEALTH_INTERVIEW.labelkey['Business City']"
                            color="primary"
                            :rules="present"
                            outlined
                            @change="updateInterview('Business City')"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                          lg="2"
                        >
                          <p>{{ getquestiontext('Business State') }}</p>
                          <v-text-field
                            v-model="HEALTH_INTERVIEW.labelkey['Business State']"
                            color="primary"
                            :rules="present"
                            outlined
                            @change="updateInterview('Business State')"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="12"
                          lg="4"
                        >
                          <p>{{ getquestiontext('Business Zipcode') }}</p>
                          <v-text-field
                            v-model="HEALTH_INTERVIEW.labelkey['Business Zipcode']"
                            color="primary"
                            :rules="numeric_only"
                            outlined
                            @change="updateInterview('Business Zipcode')"
                          />
                        </v-col>
                      </v-row>
                      <p>{{ getquestiontext('citizen') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['citizen']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'citizen', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'citizen', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                      <p v-if="HEALTH_INTERVIEW.labelkey['citizen']=== 'false'">
                        {{ getquestiontext('legal resident') }}
                      </p>
                      <v-btn-toggle
                        v-if="HEALTH_INTERVIEW.labelkey['citizen']=== 'false'"
                        v-model="HEALTH_INTERVIEW.labelkey['legal resident']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          :key="HEALTH_INTERVIEW.labelkey['legal resident']"
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'legal resident', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'legal resident', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                      <p v-if="HEALTH_INTERVIEW.labelkey['legal resident'] === 'true' && HEALTH_INTERVIEW.labelkey['citizen']=== 'false'">
                        {{ getquestiontext('visa type') }}
                      </p>
                      <v-text-field
                        v-if="HEALTH_INTERVIEW.labelkey['legal resident'] === 'true' && HEALTH_INTERVIEW.labelkey['citizen']=== 'false'"
                        v-model="HEALTH_INTERVIEW.labelkey['visa type']"
                        color="primary"
                        :rules="present"
                        outlined
                        @change="updateInterview('visa type')"
                      />
                      <p v-if="HEALTH_INTERVIEW.labelkey['legal resident'] === 'true' && HEALTH_INTERVIEW.labelkey['citizen']=== 'false'">
                        {{ getquestiontext('visa exp') }}
                      </p>
                      <v-text-field
                        v-if="HEALTH_INTERVIEW.labelkey['legal resident'] === 'true' && HEALTH_INTERVIEW.labelkey['citizen']=== 'false'"
                        v-model="HEALTH_INTERVIEW.labelkey['visa exp']"
                        color="primary"
                        :rules="present"
                        outlined
                        @change="updateInterview('visa exp')"
                      />
                      <p>{{ getquestiontext('policy owner') }}</p>
                      <v-text-field
                        v-model="HEALTH_INTERVIEW.labelkey['policy owner']"
                        color="primary"
                        :rules="present"
                        outlined
                        @change="updateInterview('policy owner')"
                      />
                      <p>{{ getquestiontext('owner relationship') }}</p>
                      <v-text-field
                        v-model="HEALTH_INTERVIEW.labelkey['owner relationship']"
                        color="primary"
                        :rules="present"
                        outlined
                        @change="updateInterview('owner relationship')"
                      />
                      <p>{{ getquestiontext('Primary Beneficiary') }}</p>
                      <v-text-field
                        v-model="HEALTH_INTERVIEW.labelkey['Primary Beneficiary']"
                        color="primary"
                        :rules="present"
                        outlined
                        @change="updateInterview('Primary Beneficiary')"
                      />
                      <p>{{ getquestiontext('Purpose of Coverage') }}</p>
                      <v-select
                        v-model="HEALTH_INTERVIEW.labelkey['Purpose of Coverage']"
                        color="primary"
                        :items="purpose_coverage"
                        :rules="present"
                        outlined
                        @change="updateInterview('Purpose of Coverage')"
                      />
                      <p>{{ getquestiontext('replacement notice') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['replacement notice']"
                        :rules="present"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'replacement notice', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'replacement notice', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                      <p>{{ getquestiontext('existing policy') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['existing policy']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'existing policy', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'existing policy', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                      <p>{{ getquestiontext('replacement intent') }}</p>
                      <v-text-field
                        v-model="HEALTH_INTERVIEW.labelkey['replacement intent']"
                        color="primary"
                        :rules="present"
                        outlined
                        @change="updateInterview('replacement intent')"
                      />
                      <p>{{ getquestiontext('applied') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['applied']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'applied', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'applied', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>

                      <p>{{ getquestiontext('declined') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['declined']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'declined', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'declined', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                      <p>{{ getquestiontext('pilot') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['pilot']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'pilot', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'pilot', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                      <p>{{ getquestiontext('diving') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['diving']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'diving', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'diving', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                      <p>{{ getquestiontext('crime') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['crime']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'crime', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'crime', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                      <p>{{ getquestiontext('probation') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['probation']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'probation', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'probation', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                      <p>{{ getquestiontext('travel') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['travel']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'travel', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'travel', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                      <p>{{ getquestiontext('dl') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['dl']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'dl', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'dl', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                      <p>{{ getquestiontext('dui') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['dui']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'dui', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'dui', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                      <p>{{ getquestiontext('reckless') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['reckless']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'reckless', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'reckless', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                      <p>{{ getquestiontext('Annual Income') }}</p>
                      <v-select
                        v-model="HEALTH_INTERVIEW.labelkey['Annual Income']"
                        :rules="present"
                        :items="Income"
                        label="Income"
                        item-value="code"
                        item-text="description"
                        color="primary"
                        outlined
                        @change="updateInterview('Annual Income')"
                      />
                      <p>{{ getquestiontext('Net Worth') }}</p>
                      <v-select
                        v-model="HEALTH_INTERVIEW.labelkey['Net Worth']"
                        :rules="present"
                        :items="netWorth"
                        label="Net Worth"
                        item-value="code"
                        item-text="description"
                        color="primary"
                        outlined
                        @change="updateInterview('Net Worth')"
                      />
                      <p>{{ getquestiontext('marijuana') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['marijuana']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'marijuana', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'marijuana', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                      <p>{{ getquestiontext('drugs') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['drugs']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'drugs', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'drugs', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                      <p>{{ getquestiontext('drug habit') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['drug habit']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'drug habbit', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'drug habbit', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                      <p>{{ getquestiontext('rehab') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['rehab']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'rehab', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'rehab', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                      <p>{{ getquestiontext('surgery') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['surgery']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'surgery', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'surgery', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                      <p>{{ getquestiontext('disability') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['disability']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'disability', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'disability', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                      <p>{{ getquestiontext('prescription abuse') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['prescription abuse']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'prescription abuse', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'prescription abuse', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                </v-card>

                <v-btn
                  color="primary"
                  :disabled="validlifestyle"
                  @click="nextquestion('term life compare quotes')"
                >
                  Continue
                </v-btn>

                <v-btn
                  text
                  @click="cancel"
                >
                  Previous
                </v-btn>
              </v-stepper-content>

              <v-stepper-content step="3">
                <v-card
                  min-height="300"
                  elevation="0"
                >
                  <div
                    class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"
                  >
                    Health Questionnaire
                    <v-row justify="center">
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        lg="4"
                      >
                        <v-divider
                          class="test text-center"
                        />
                      </v-col>
                    </v-row>
                  </div>
                  <div
                    class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"
                  />
                  <v-row class="justify-center">
                    <v-col
                      cols="12"
                      sm="12"
                      md="8"
                    >
                      <p>{{ getquestiontext('Physician') }}</p>
                      <v-text-field
                        v-model="HEALTH_INTERVIEW.labelkey['Physician']"
                        color="primary"
                        :rules="present"
                        outlined
                        @change="updateInterview('Physician')"
                      />
                      <p>{{ getquestiontext('doctor address') }}</p>
                      <v-text-field
                        v-model="HEALTH_INTERVIEW.labelkey['doctor address']"
                        color="primary"
                        :rules="present"
                        outlined
                        @change="updateInterview('doctor address')"
                      />
                      <v-row>
                        <v-col
                          cols="12"
                          md="5"
                        >
                          <p>{{ getquestiontext('doctor city') }}</p>
                          <v-text-field
                            v-model="HEALTH_INTERVIEW.labelkey['doctor city']"
                            color="primary"
                            :rules="present"
                            outlined
                            @change="updateInterview('doctor city')"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="2"
                        >
                          <p>{{ getquestiontext('doctor state') }}</p>
                          <v-text-field
                            v-model="HEALTH_INTERVIEW.labelkey['doctor state']"
                            color="primary"
                            :rules="present"
                            outlined
                            @change="updateInterview('doctor state')"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="5"
                        >
                          <p>{{ getquestiontext('doctor postcode') }}</p>
                          <v-text-field
                            v-model="HEALTH_INTERVIEW.labelkey['doctor postcode']"
                            color="primary"
                            :rules="numeric_only"
                            outlined
                            @change="updateInterview('doctor postcode')"
                          />
                        </v-col>
                      </v-row>

                      <p>{{ getquestiontext('doctor date') }}</p>
                      <v-text-field
                        v-model="HEALTH_INTERVIEW.labelkey['doctor date']"
                        color="primary"
                        :rules="present"
                        outlined
                        @change="updateInterview('doctor date')"
                      />
                      <p>{{ getquestiontext('doctor reason') }}</p>
                      <v-text-field
                        v-model="HEALTH_INTERVIEW.labelkey['doctor reason']"
                        color="primary"
                        :rules="present"
                        outlined
                        @change="updateInterview('doctor reason')"
                      />
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <p>{{ getquestiontext('height in feet') }}</p>
                          <v-text-field
                            v-model="HEALTH_INTERVIEW.labelkey['height in feet']"
                            color="primary"
                            :rules="numeric_only"
                            outlined
                            @change="updateInterview('height in feet')"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <p>{{ getquestiontext('height inches') }}</p>
                          <v-text-field
                            v-model="HEALTH_INTERVIEW.labelkey['height inches']"
                            color="primary"
                            :rules="numeric_only"
                            outlined
                            @change="updateInterview('height inches')"
                          />
                        </v-col>
                      </v-row>

                      <p>{{ getquestiontext('weight') }}</p>
                      <v-text-field
                        v-model="HEALTH_INTERVIEW.labelkey['weight']"
                        color="primary"
                        :rules="numeric_only"
                        outlined
                        @change="updateInterview('weight')"
                      />
                      <p>{{ getquestiontext('weight loss') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['weight loss']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'weight loss', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'weight loss', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                      <p>{{ getquestiontext('nicotine') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['nicotine']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'nicotine', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'nicotine', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                      <v-row v-if="HEALTH_INTERVIEW.labelkey['nicotine'] === 'true'">
                        <v-col
                          cols="12"
                          md="12"
                        >
                          <p>Frequency of use:</p>
                          <v-select
                            v-model="HEALTH_INTERVIEW.labelkey['smoking frequency']"
                            :rules="present"
                            :items="frequency_options"
                            label="Select"
                            color="primary"
                            outlined
                            @change="updateInterview('smoking frequency')"
                          />
                          <p>Date last used</p>
                          <v-text-field
                            v-model="HEALTH_INTERVIEW.labelkey['smoking date']"
                            :rules="present"
                            label="Date"
                            color="primary"
                            outlined
                            @change="updateInterview('smoking date')"
                          />
                        </v-col>
                      </v-row>
                      <p>{{ getquestiontext('heart') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['heart']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'heart', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'heart', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                      <p>{{ getquestiontext('hiv') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['hiv']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'hiv', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'hiv', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                      <p>{{ getquestiontext('stroke') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['stroke']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'stroke', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'stroke', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                      <p>{{ getquestiontext('Diabetes') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['Diabetes']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'Diabetes', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'Diabetes', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                      <p>{{ getquestiontext('blood') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['blood']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'blood', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'blood', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                      <p>{{ getquestiontext('immune') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['immune']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'immune', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'immune', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                      <p>{{ getquestiontext('pressure') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['pressure']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'pressure', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'pressure', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                      <p>{{ getquestiontext('lungs') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['lungs']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'lungs', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'lungs', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                      <p>{{ getquestiontext('cancer') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['cancer']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'cancer', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'cancer', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                      <p>{{ getquestiontext('mental') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['mental']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'mental', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'mental', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                      <p>{{ getquestiontext('stomach') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['stomach']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'stomach', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'stomach', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                      <p>{{ getquestiontext('brain') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['brain']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'brain', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'brain', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                      <p>{{ getquestiontext('kidneys') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['kidneys']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'kidneys', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'kidneys', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                      <p>{{ getquestiontext('glands') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['glands']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'glands', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'glands', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                      <p>{{ getquestiontext('pain') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['pain']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'pain', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'pain', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                      <p>{{ getquestiontext('autoimmune') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['autoimmune']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'autoimmune', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'autoimmune', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                </v-card>

                <v-btn
                  color="primary"
                  :disabled="validhealth"
                  @click="nextquestion('applicant details')"
                >
                  Continue
                </v-btn>

                <v-btn
                  text
                  @click="cancel"
                >
                  Previous
                </v-btn>
              </v-stepper-content>
              <v-stepper-content step="4">
                <v-card
                  min-height="300"
                  elevation="0"
                >
                  <div
                    class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"
                  >
                    Family History and Review
                    <v-row justify="center">
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        lg="4"
                      >
                        <v-divider
                          class="test text-center"
                        />
                      </v-col>
                    </v-row>
                  </div>
                  <v-row class="justify-center">
                    <v-col
                      cols="12"
                      md="8"
                    >
                      <p>{{ getquestiontext('father history') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['father history']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'father history', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'father history', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                      <p>{{ getquestiontext('history father living') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['history father living']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'history father living', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'history father living', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                      <v-row v-if="HEALTH_INTERVIEW.labelkey['history father living'] === 'true'">
                        <v-col
                          cols="12"
                        >
                          <p>Please list cause and age at death:</p>
                          <v-text-field
                            v-model="HEALTH_INTERVIEW.labelkey['father age death']"
                            color="primary"
                            :rules="numeric_only"
                            outlined
                            @change="updateInterview('father age death')"
                          />
                        </v-col>
                      </v-row>
                      <p>{{ getquestiontext('history mother') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['history mother']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'history mother', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'history mother', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                      <p>{{ getquestiontext('history mother living') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['history mother living']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'history mother living', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'history mother living', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                      <v-row v-if="HEALTH_INTERVIEW.labelkey['history mother living'] === 'true'">
                        <v-col
                          cols="12"
                        >
                          <p>Please list cause and age at death:</p>
                          <v-text-field
                            v-model="HEALTH_INTERVIEW.labelkey['mother age death']"
                            color="primary"
                            :rules="numeric_only"
                            outlined
                            @change="updateInterview('mother age death')"
                          />
                        </v-col>
                      </v-row>
                      <p>{{ getquestiontext('siblings') }}</p>
                      <v-text-field
                        v-model="HEALTH_INTERVIEW.labelkey['siblings']"
                        color="primary"
                        :rules="numeric_only"
                        outlined
                        @change="updateInterview('siblings')"
                      />
                      <p>{{ getquestiontext('history sibling') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['history sibling']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'history sibling', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'history sibling', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                      <p>{{ getquestiontext('history siblings living') }}</p>
                      <v-btn-toggle
                        v-model="HEALTH_INTERVIEW.labelkey['history siblings living']"
                        mandatory
                        class="mb-4"
                      >
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="true"
                          @click="updateToggle({key: 'history siblings living', val:'true'})"
                        >
                          Yes
                        </v-btn>
                        <v-btn
                          color="blanketbutton"
                          text
                          outlined
                          value="false"
                          @click="updateToggle({key: 'history siblings living', val:'false'})"
                        >
                          No
                        </v-btn>
                      </v-btn-toggle>
                      <v-row v-if="HEALTH_INTERVIEW.labelkey['history siblings living'] === 'true'">
                        <v-col
                          cols="12"
                        >
                          <p>Please list cause and age at death</p>
                          <v-text-field
                            v-model="HEALTH_INTERVIEW.labelkey['siblings age death']"
                            :rules="numeric_only"
                            color="primary"
                            outlined
                            @change="updateInterview('siblings age death')"
                          />
                        </v-col>
                      </v-row>
                      <p>{{ getquestiontext('billing') }}</p>
                      <v-text-field
                        v-model="HEALTH_INTERVIEW.labelkey['billing']"
                        color="primary"
                        :rules="present"
                        outlined
                        @change="updateInterview('billing')"
                      />
                      <p>{{ getquestiontext('payment method') }}</p>
                      <v-text-field
                        v-model="HEALTH_INTERVIEW.labelkey['payment method']"
                        color="primary"
                        :rules="present"
                        outlined
                        @change="updateInterview('payment method')"
                      />
                      <p>{{ getquestiontext('etf payer name') }}</p>
                      <v-text-field
                        v-model="HEALTH_INTERVIEW.labelkey['etf payer name']"
                        color="primary"
                        :rules="present"
                        outlined
                        @change="updateInterview('etf payer name')"
                      />
                      <p>{{ getquestiontext('eft insured name') }}</p>
                      <v-text-field
                        v-model="HEALTH_INTERVIEW.labelkey['eft insured name']"
                        color="primary"
                        :rules="present"
                        outlined
                        @change="updateInterview('eft insured name')"
                      />
                      <p>{{ getquestiontext('eft account') }}</p>
                      <v-text-field
                        v-model="HEALTH_INTERVIEW.labelkey['eft account']"
                        color="primary"
                        :rules="present"
                        outlined
                        @change="updateInterview('eft account')"
                      />
                      <p>{{ getquestiontext('eft bank') }}</p>
                      <v-text-field
                        v-model="HEALTH_INTERVIEW.labelkey['eft bank']"
                        color="primary"
                        :rules="present"
                        outlined
                        @change="updateInterview('eft bank')"
                      />
                      <p>{{ getquestiontext('eft account number') }}</p>
                      <v-text-field
                        v-model="HEALTH_INTERVIEW.labelkey['eft account number']"
                        color="primary"
                        :rules="present"
                        outlined
                        @change="updateInterview('eft account number')"
                      />
                      <p>{{ getquestiontext('eft transit number') }}</p>
                      <v-text-field
                        v-model="HEALTH_INTERVIEW.labelkey['eft transit number']"
                        color="primary"
                        :rules="present"
                        outlined
                        @change="updateInterview('eft transit number')"
                      />
                      <p>{{ getquestiontext('eft bank address') }}</p>
                      <v-text-field
                        v-model="HEALTH_INTERVIEW.labelkey['eft bank address']"
                        color="primary"
                        :rules="present"
                        outlined
                        @change="updateInterview('eft bank address')"
                      />
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                          lg="6"
                        >
                          <p>{{ getquestiontext('eft bank city') }}</p>
                          <v-text-field
                            v-model="HEALTH_INTERVIEW.labelkey['eft bank city']"
                            color="primary"
                            :rules="present"
                            outlined
                            @change="updateInterview('eft bank city')"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                          lg="6"
                        >
                          <p>{{ getquestiontext('eft bank postcode') }}</p>
                          <v-text-field
                            v-model="HEALTH_INTERVIEW.labelkey['eft bank postcode']"
                            color="primary"
                            :rules="numeric_only"
                            outlined
                            @change="updateInterview('eft bank postcode')"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>

                <v-btn
                  color="primary"
                  :disabled="validreview"
                  @click="endtermsapplication"
                >
                  Finish
                </v-btn>

                <v-btn
                  text
                  @click="cancel"
                >
                  Previous
                </v-btn>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { emailRegex } from '@/consts/regex'
  var numeral = require('numeral')

  export default {
    // bday en Your birth date is:
    // height en What is your height?
    // weight in lbs en What is your weight in pounds?
    // nic en In the past 5 years have you used any form of tobacco or nicotine (including e-cigs, patches or gum)?
    name: 'TermCoverage',
    props: ['id'],
    data: () => ({
      citizen: 'false',
      e1: 1,
      f6: 1,
      termlength: '',
      testval: 'false',
      providerloading: false,
      policyvalue: '',
      provider: '',
      netWorth: [
        {
          code: 'LESS_THAN_100000',
          description: 'Less than $100,000',
          listName: 'NetWorth'
        },
        {
          code: '$100,001-$250,000',
          description: '$100,001- $250,000',
          listName: 'NetWorth'
        },
        {
          code: '$250,001-$500,000',
          description: '$250,001-$500,000',
          listName: 'NetWorth'
        },
        {
          code: '$500,001-$750,000',
          description: '$500,001-$750,000',
          listName: 'NetWorth'
        },
        {
          code: '$750,001-$1,000,000',
          description: '$750,001-$1,000,000',
          listName: 'NetWorth'
        },
        {
          code: '1,000,001_and_up',
          description: '$1,000,001 and up',
          listName: 'NetWorth'
        },
        {
          code: '0',
          description: 'Unknown',
          listName: 'NetWorth'
        },
      ],
      Income: [
        {
          code: 'Less_than_$25,000',
          description: 'Less than $25,000',
          listName: 'Income'
        },
        {
          code: '$25,001-$50,000',
          description: '$25,001-$50,000',
          listName: 'Income'
        },
        {
          code: '$50,001-$75,000',
          description: '$50,001-$75,000',
          listName: 'Income'
        },
        {
          code: '$75,001-$100,000',
          description: '$75,001-$100,000',
          listName: 'Income'
        },
        {
          code: '$100,001-$150,000',
          description: '$100,001-$150,000',
          listName: 'Income'
        },
        {
          code: '$150,001-$200,000',
          description: '$150,001-$200,000',
          listName: 'Income'
        },
        {
          code: '$200,001-$250,000',
          description: '$200,001-$250,000',
          listName: 'Income'
        },
        {
          code: '$250,001_and_up',
          description: '$250,001 and up',
          listName: 'Income'
        },
        {
          code: '0',
          description: 'Unknown',
          listName: 'Income'
        },
      ],
      present: [
        v => !!v || 'Field is required',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => emailRegex.test(v) || 'E-mail must be valid',
      ],
      numeric_only: [
        v => !!v || 'This field is required',
        v => /^[0-9]*$/.test(v) || 'Numeric values only',
      ],
      HEALTH_INTERVIEW: {
        labelkey: []
      },
      policyoptions: {
        termlength: '',
        policyvalue: '',
        provider: [],
        policytype: 'TermLife',
        monthlypremium: ''
      },
      selected: [],
      currentuser: {
        email: '',
        ssn: '',
        id: '',
        alerts: '',
        status: '',
        firstname: '',
        lastname: '',
        mobile: '',
        address: '',
        city: '',
        country: 'US',
        postcode: '',
      },
      userhealth: {

      },
      useracttivities: {

      },
      userhistory: {

      },
      max: 50,
      allowSpaces: true,
      marital_options: [
        'Married', 'Separated', 'Single', 'Widowed', 'Divorced'
      ],
      purpose_coverage: ['Income Replacement', 'Debt Repayment', 'Estate Planning or Conservation', 'Other'],
      business_coverage: ['Key Person', 'Buy or Sell', 'Estate Planning or Conservation', 'Deferred Compensation', 'Loan Protection', 'Other'],
      frequency_options: ['Daily', 'Occasionally or Socially', 'No Longer Use'],
      unique: true,
      quotekey: '',
      activePicker: 'YEAR',
      date: null,
      menu: false,
      filterchars: true,
      onlyspace: false,
      loadedsites: '',

    }),
    computed: {
      loading () {
        return this.$store.getters.getloading
      },
      interview () {
        return this.$store.getters.getcurrentquote.interview
      },
      validpersonal () {
        let blockuser = true
        let user = this.currentuser
        // if all fields have values, send it
        if (this.currentuser.firstname &&
          this.currentuser.lastname &&
          this.currentuser.address &&
          this.currentuser.city &&
          this.currentuser.postcode &&
          this.currentuser.height.feet &&
          this.currentuser.height.inches &&
          this.currentuser.mobile &&
          this.currentuser.ssn &&
          this.currentuser.weight) {
          blockuser = false
          console.log('not blocking')
          this.$store.dispatch('checkpostcode', this.currentuser.postcode)
          let key = 'firstname'
          let rec = {
            quotekey: this.quotekey,
            questionkey: key,
            answer: this.currentuser.firstname
          }
          this.$store.dispatch('updateAnicoTermLifeInterview', rec)
          key = 'lastname'
          rec = {
            quotekey: this.quotekey,
            questionkey: key,
            answer: this.currentuser.lastname
          }
          this.$store.dispatch('updateAnicoTermLifeInterview', rec)
          key = 'city'
          rec = {
            quotekey: this.quotekey,
            questionkey: key,
            answer: this.currentuser.city
          }
          this.$store.dispatch('updateAnicoTermLifeInterview', rec)
          key = 'postcode'
          rec = {
            quotekey: this.quotekey,
            questionkey: key,
            answer: this.currentuser.postcode
          }
          this.$store.dispatch('updateAnicoTermLifeInterview', rec)
          key = 'ssn'
          rec = {
            quotekey: this.quotekey,
            questionkey: key,
            answer: this.currentuser.ssn
          }
          this.$store.dispatch('updateAnicoTermLifeInterview', rec)
          key = 'mobile'
          rec = {
            quotekey: this.quotekey,
            questionkey: key,
            answer: this.currentuser.mobile
          }
          this.$store.dispatch('updateAnicoTermLifeInterview', rec)
          key = 'address'
          rec = {
            quotekey: this.quotekey,
            questionkey: key,
            answer: this.currentuser.address
          }
          this.$store.dispatch('updateAnicoTermLifeInterview', rec)
          key = 'dob'
          rec = {
            quotekey: this.quotekey,
            questionkey: key,
            answer: this.currentuser.dob
          }
          this.$store.dispatch('updateAnicoTermLifeInterview', rec)
        }
        return blockuser
      },
      validlifestyle () {
        let blockuser = false
        if (
          this.HEALTH_INTERVIEW.labelkey.occupation &&
          this.HEALTH_INTERVIEW.labelkey.employer &&
          this.HEALTH_INTERVIEW.labelkey['business type'] &&
          this.HEALTH_INTERVIEW.labelkey.Duties &&
          this.HEALTH_INTERVIEW.labelkey['Business Address'] &&
          this.HEALTH_INTERVIEW.labelkey['Business City'] &&
          this.HEALTH_INTERVIEW.labelkey['Business State'] &&
          this.HEALTH_INTERVIEW.labelkey['Business Zipcode'] &&
          this.HEALTH_INTERVIEW.labelkey.citizen &&
          this.HEALTH_INTERVIEW.labelkey['policy owner'] &&
          this.HEALTH_INTERVIEW.labelkey['owner relationship'] &&
          this.HEALTH_INTERVIEW.labelkey['Primary Beneficiary'] &&
          this.HEALTH_INTERVIEW.labelkey['Purpose of Coverage'] &&
          this.HEALTH_INTERVIEW.labelkey['Purpose of Coverage'] &&
          this.HEALTH_INTERVIEW.labelkey['Purpose of Coverage'] &&
          this.HEALTH_INTERVIEW.labelkey['replacement notice'] &&
          this.HEALTH_INTERVIEW.labelkey['existing policy'] &&
          this.HEALTH_INTERVIEW.labelkey['replacement intent'] &&
          this.HEALTH_INTERVIEW.labelkey.applied &&
          this.HEALTH_INTERVIEW.labelkey.declined &&
          this.HEALTH_INTERVIEW.labelkey.pilot &&
          this.HEALTH_INTERVIEW.labelkey.diving &&
          this.HEALTH_INTERVIEW.labelkey.crime &&
          this.HEALTH_INTERVIEW.labelkey.probation &&
          this.HEALTH_INTERVIEW.labelkey.travel &&
          this.HEALTH_INTERVIEW.labelkey.dl &&
          this.HEALTH_INTERVIEW.labelkey.dui &&
          this.HEALTH_INTERVIEW.labelkey.reckless &&
          this.HEALTH_INTERVIEW.labelkey['Annual Income'] &&
          this.HEALTH_INTERVIEW.labelkey['Net Worth'] &&
          this.HEALTH_INTERVIEW.labelkey.marijuana &&
          this.HEALTH_INTERVIEW.labelkey.drugs &&
          this.HEALTH_INTERVIEW.labelkey['drug habit'] &&
          this.HEALTH_INTERVIEW.labelkey.rehab &&
          this.HEALTH_INTERVIEW.labelkey.surgery &&
          this.HEALTH_INTERVIEW.labelkey.disability &&
          this.HEALTH_INTERVIEW.labelkey['prescription abuse']
        ) {
          console.log('NOT BLOCKING')
          blockuser = false
        }
        console.log(this.HEALTH_INTERVIEW.labelkey.pilot,
                    this.HEALTH_INTERVIEW.labelkey.diving,
                    this.HEALTH_INTERVIEW.labelkey.crime,
                    this.HEALTH_INTERVIEW.labelkey.probation,
                    this.HEALTH_INTERVIEW.labelkey.travel,
                    this.HEALTH_INTERVIEW.labelkey['Marital Status'])
        // 41 min
        // this.HEALTH_INTERVIEW.labelkey[]
        return blockuser
      },
      validhealth () {
        let blockuser = false
        if (
          this.HEALTH_INTERVIEW.labelkey.Physician &&
          this.HEALTH_INTERVIEW.labelkey['doctor address'] &&
          this.HEALTH_INTERVIEW.labelkey['doctor city'] &&
          this.HEALTH_INTERVIEW.labelkey['doctor state'] &&
          this.HEALTH_INTERVIEW.labelkey['doctor postcode'] &&
          this.HEALTH_INTERVIEW.labelkey['doctor date'] &&
          this.HEALTH_INTERVIEW.labelkey['doctor reason'] &&
          this.HEALTH_INTERVIEW.labelkey['height in feet'] &&
          this.HEALTH_INTERVIEW.labelkey['height inches'] &&
          this.HEALTH_INTERVIEW.labelkey['weight loss'] &&
          this.HEALTH_INTERVIEW.labelkey.nicotine &&
          this.HEALTH_INTERVIEW.labelkey.heart &&
          this.HEALTH_INTERVIEW.labelkey.hiv &&
          this.HEALTH_INTERVIEW.labelkey.stroke &&
          this.HEALTH_INTERVIEW.labelkey.Diabetes &&
          this.HEALTH_INTERVIEW.labelkey.blood &&
          this.HEALTH_INTERVIEW.labelkey.immune &&
          this.HEALTH_INTERVIEW.labelkey.pressure &&
          this.HEALTH_INTERVIEW.labelkey.lungs &&
          this.HEALTH_INTERVIEW.labelkey.cancer &&
          this.HEALTH_INTERVIEW.labelkey.mental &&
          this.HEALTH_INTERVIEW.labelkey.stomach &&
          this.HEALTH_INTERVIEW.labelkey.brain &&
          this.HEALTH_INTERVIEW.labelkey.surgery &&
          this.HEALTH_INTERVIEW.labelkey.kidneys &&
          this.HEALTH_INTERVIEW.labelkey.glands &&
          this.HEALTH_INTERVIEW.labelkey.pain &&
          this.HEALTH_INTERVIEW.labelkey.autoimmune
        ) {
          blockuser = false
        }
        return blockuser
      },
      validreview () {
        let blockuser = false
        if (
          this.HEALTH_INTERVIEW.labelkey['father history'] &&
          this.HEALTH_INTERVIEW.labelkey['history father living'] &&
          this.HEALTH_INTERVIEW.labelkey['history mother'] &&
          this.HEALTH_INTERVIEW.labelkey['history mother living'] &&
          this.HEALTH_INTERVIEW.labelkey.siblings &&
          this.HEALTH_INTERVIEW.labelkey['history sibling'] &&
          this.HEALTH_INTERVIEW.labelkey['history siblings living'] &&
          this.HEALTH_INTERVIEW.labelkey.billing &&
          this.HEALTH_INTERVIEW.labelkey['payment method'] &&
          this.HEALTH_INTERVIEW.labelkey['etf payer name'] &&
          this.HEALTH_INTERVIEW.labelkey['eft insured name'] &&
          this.HEALTH_INTERVIEW.labelkey['eft account'] &&
          this.HEALTH_INTERVIEW.labelkey['eft bank'] &&
          this.HEALTH_INTERVIEW.labelkey['eft account number'] &&
          this.HEALTH_INTERVIEW.labelkey['eft transit number'] &&
          this.HEALTH_INTERVIEW.labelkey['eft bank address'] &&
          this.HEALTH_INTERVIEW.labelkey['eft bank city'] &&
          this.HEALTH_INTERVIEW.labelkey['eft bank postcode']
        ) {
          blockuser = false
        }
        return blockuser
      },
      getproviders () {
        let providers = ''
        providers = this.$store.getters.getProviderRecommendations
        // console.log('we have provider data ', providers)
        return providers
      },
      getpolicyvalue () {
        return numeral(this.policyamounts[this.policyvalue]).format('$0,000')
      },
      getpoicydetails () {
        return this.$store.getters.getcurrentquote
      }
    },
    watch: {
      interview (val, oldVal) {
        // The quote values are dynamic
        // Once the db returns them we need to set them but we can't do it before
        // watch the value of this computed prop and when it changes
        // populate our form values for WHAT WE HAVE
        let keys = Object.keys(val)
        keys.forEach(keyval => {
          this.HEALTH_INTERVIEW.labelkey[keyval] = val[keyval]
        })
      },
      loading (val) {
        console.log('watch loading change is ', this.loading)
      },
      menu (val) {
        val && setTimeout(() => {
          this.$refs.picker.activePicker = 'YEAR'
          this.pickerDate = null
        })
      },
      providerloading (val) {
        if (!val) return

        setTimeout(() => (this.providerloading = false), 2000)
      },
      sitename: 'validateField',
      max: 'validateField',
      model: 'validateField',
    },
    created () {
      this.$store.dispatch('loadAnicoAppQuestions')
      this.quotekey = this.$route.params.key
      this.currentuser = this.$store.getters.user
      this.appQuestions = this.$store.getters.getAnicoAppQuestions
      let rec = {
        quotekey: this.quotekey,
        user: this.user
      }
      this.$store.dispatch('loadquote', rec)
      this.$gtag.pageview('AnicoTermLifeApplication')
      this.$gtag.event('anicoLifeApplicattionStarted')
      this.appQuestions.forEach(entry => {
        Object.assign(entry, { useranswer: '' })
      })
      // if (this.$store.getters.getcurrentquote) {
      //   this.HEALTH_INTERVIEW = this.$store.getters.getcurrentquote.inte
      // } else {
      //   console.log('******** no interview data to set *********')
      // }
      // this.HEALTH_INTERVIEW = this.$store.getters.getcurrentquote
    },

    methods: {
      season (val) {
        return this.icons[val]
      },
      getquestiontext (label) {
        let qdetail = ''
        qdetail = this.appQuestions.find(entry => entry.label === label)
        if (qdetail) {
          return qdetail.question
        }
        return 'unknown'
      },
      updateToggle (obj) {
        console.log(obj)
        let rec = {
          quotekey: this.quotekey,
          questionkey: obj.key,
          answer: obj.val
        }
        this.$store.dispatch('updateAnicoTermLifeInterview', rec)
      },
      updateInterview (key, val) {
        console.log('updating ', key, this.HEALTH_INTERVIEW.labelkey[key], val)
        // this.quotekey
        if (!key) {
          return
        }
        let rec = {
          quotekey: this.quotekey,
          questionkey: key,
          answer: this.HEALTH_INTERVIEW.labelkey[key]
        }
        let value = this.HEALTH_INTERVIEW.labelkey[key]
        this.$store.dispatch('updateAnicoTermLifeInterview', rec)
      },
      nextquestion (completedQuestion) {
        let application = {
          timestamp: Date.now(),
          quotekey: this.quotekey,
          user: this.user,
        }

        let interview = Object.values(this.HEALTH_INTERVIEW)
        console.log('data ', interview[0])
        this.$gtag.event('anicoLifeApplicattionAnswer')
        this.e1++
        // this.$gtag.event('Symetra Term Life Applicaton ', { question: completedQuestion, campaign: 'none' })
        console.log('step completed ', completedQuestion)
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      },
      cancel () {
        this.e1--
        if (this.e1 < 1) {
          this.e1 = 1
        }
      },
      requestproviderdata () {

      },
      endtermsapplication () {
        console.log('ENDING APP')
        console.log(this.$store.getters.getcurrentquote)
        // any question not answered fill in a placeholder
        this.HEALTH_INTERVIEW.labelkey.firstname = this.currentuser.firstname
        this.HEALTH_INTERVIEW.labelkey.lastname = this.currentuser.lastname
        this.HEALTH_INTERVIEW.labelkey.address = this.currentuser.address
        this.HEALTH_INTERVIEW.labelkey.ssn = this.currentuser.ssn
        this.HEALTH_INTERVIEW.labelkey.mobile = this.currentuser.mobile
        this.HEALTH_INTERVIEW.labelkey.age = this.$store.getters.getage
        this.HEALTH_INTERVIEW.labelkey.dob = this.currentuser.dob
        this.HEALTH_INTERVIEW.labelkey.country = 'USA'
        this.HEALTH_INTERVIEW.labelkey.statecode = this.$store.getters.postcodedata
        this.HEALTH_INTERVIEW.labelkey.birthstate = this.$store.getters.postcodedata
        this.HEALTH_INTERVIEW.labelkey.birthplace = this.currentuser.city
        this.HEALTH_INTERVIEW.labelkey.email = this.currentuser.email
        this.HEALTH_INTERVIEW.labelkey.termlength = this.$store.getters.getcurrentquote.termlength
        this.HEALTH_INTERVIEW.labelkey.facevalue = this.$store.getters.getcurrentquote.policyvalue

        this.appQuestions.forEach(entry => {
          if (!this.HEALTH_INTERVIEW.labelkey[entry.label]) {
            console.log('********* NO VALUE FOR ', entry.label)
            this.HEALTH_INTERVIEW.labelkey[entry.label] = ''
          }
        })
        let facevalue = this.$store.getters.getcurrentquote.policyvalue
        let provider = this.$store.getters.getcurrentquote.provider
        let termlength = this.$store.getters.getcurrentquote.termlength
        // let interview = []
        // let interviewkeys = Object.keys(this.HEALTH_INTERVIEW.labelkey)
        // maybe we do not need this now as we wrote the value as it was put in
        // for (let key of interviewkeys) {
        //   // console.log('key ', key, ' val ', this.HEALTH_INTERVIEW.labelkey[key])
        //   Object.assign(interview, { [key]: this.HEALTH_INTERVIEW.labelkey[key] })
        //   // interview.({ questionkey: key, answervalue: this.HEALTH_INTERVIEW.labelkey[key] })
        // }
        let application = {
          policyvalue: facevalue,
          provider: provider,
          termlength: termlength,
          timestamp: Date.now(),
          quotekey: this.quotekey,
          user: this.user,
        }
        this.$store.dispatch('saveTermlifeAnicoApplication', application)
        // updateQuote
        // this.$store.dispatch('updateQuote', this.policyoptions)
        // if its complete
        // send for processing too

        // this.$store.dispatch('createtermlifepolicy', this.policyoptions)
        this.$gtag.event('anicoLifeApplicationEnded')
        this.$router.push({ path: '/' })
      },
      termicon (val) {
        return this.termicons[val]
      },
      getpostcodedata (postcode) {
        this.$store.dispatch('checkpostcode', postcode)
        this.e6 = '2'
      },
      selectcoverage () {
        console.log('pushing to new view')
        this.$router.push({ path: '/TermCoverage' })
      },
      save (date) {
        this.$refs.menu.save(date)
      },
      validateField () {
        this.$refs.form1.validate()
      },
      validate () {
        this.$refs.form.validate()
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
      resetTop () {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      },
      validateForm (scope) {
        return this.$validator.validateAll(scope)
      }
    }
  }
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: rgba(0, 157, 179, 1);
}
.coverage-large {
    font-size: 30px;
    display: block;
    text-align: center;
    font-weight: 500;
    line-height: 34px;
    margin-top: 50px;
}
.total-calculate{
  display: flex;
  justify-content: center!important;flex-wrap:wrap; border:1px solid #ccc; padding: 5px 20px; max-width: 450px; margin: 0 auto; border-radius: 20px;}

.total-calculateh3{display: block; width:100%; text-align: center; border:1px solid #ccc; padding: 5px 20px; font-size:42px; color:#129fb1; font-family: 'formadjrdeckbold'; border-radius: 20px;}
.total-calculatep{display: block; width:100%; text-align: center; font-size:14px; margin: 0;}

.test {
    border-width: 1px !important;
    border-color:#0d9eb0 !important;
    height: 100%;
    justify-content: center;
    text-align: center;
}
 .z-btn {
   display: inline-block;
   height: 54px;
   line-height: 48px;
   border: 2px solid #009db3;
   background: #009db3;
   color: #ffffff;
   padding: 0 30px;
   -webkit-border-radius: 10px;
   -moz-border-radius: 10px;
   border-radius: 10px;
   font-weight: 700;
   text-transform: capitalize;
   min-width: 160px;
   text-align: center;
 }
</style>
<style lang="sass">

  .v-card.v-card.v-card--account
    border-color: currentColor
    border-width: 4px

    .v-icon
      color: inherit

  .v-card--account,
  .v-card--account:before
    border-radius: 50%

</style>
